"use client";

import * as Sentry from "@sentry/nextjs";
import Error from "next/error";
import React, { useEffect } from "react";
import { FiveHundredInner } from "./global-error";
export default function FiveHundred({
  error
}: {
  error: Error;
}) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);
  return <FiveHundredInner data-sentry-element="FiveHundredInner" data-sentry-component="FiveHundred" data-sentry-source-file="error.tsx" />;
}